
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { ScrollComponent } from "@/assets/ts/components/_ScrollComponent";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { version } from "@/core/helpers/documentation";
import { asideMenuIcons } from "@/core/helpers/config";
import MainMenuConfig from "./Menu";

export default defineComponent({
  name: "kt-menu",
  components: {},
  data() {
    return {
      MainMenu: MainMenuConfig,
    };
  },
  methods: {
    async actioncheck() {
      let menu = JSON.parse(localStorage.getItem("menu") || "{}");
      if (menu) {
        for (let i = 0; i < menu.length; i++) {
          //console.log(menu[i].action);
          if (menu[i].heading == "Trainer") {
            //console.log(menu[i].action);
            let actions = menu[i].action;
            console.log(actions);
            for (let j = 0; j < actions.length; j++) {
              if (actions[j].action_name === "Create") {
                this.MainMenu = [
                  {
                    heading: "menu",
                    route: "/assessor",
                    pages: [
                      {
                        heading: "Add Assessor",
                        route: "/assessor/add-assessor",
                        fontIcon: "fa-user-secret",
                        svgIcon: "media/icons/duotune/general/gen019.svg",
                      },
                      {
                        heading: "Assessor List",
                        route: "/assessor/assessor-list",
                        fontIcon: "fa-user-secret",
                        svgIcon: "media/icons/duotune/general/gen019.svg",
                      },
                      {
                        heading: "Assessor Search",
                        route: "/assessor/assessor-search",
                        fontIcon: "fa-user-secret",
                        svgIcon: "media/icons/duotune/general/gen019.svg",
                      },
                    ],
                  },
                ];
              }
              if (
                actions[j].action_name !== "Create" &&
                actions[j].action_name === "Edit"
              ) {
                this.MainMenu = [
                  {
                    heading: "menu",
                    route: "/assessor",
                    pages: [
                      {
                        heading: "Assessor List",
                        route: "/assessor/assessor-list",
                        fontIcon: "fa-user-secret",
                        svgIcon: "media/icons/duotune/general/gen019.svg",
                      },
                      {
                        heading: "Assessor Search",
                        route: "/assessor/assessor-search",
                        fontIcon: "fa-user-secret",
                        svgIcon: "media/icons/duotune/general/gen019.svg",
                      },
                    ],
                  },
                ];
              }
            }
          }
        }
      }
    },
  },
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const scrollElRef = ref<null | HTMLElement>(null);

    onMounted(() => {
      ScrollComponent.reinitialization();
      MenuComponent.reinitialization();
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    return {
      hasActiveChildren,
      MainMenuConfig,
      asideMenuIcons,
      version,
      translate,
    };
  },
});
