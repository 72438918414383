const Menu = [
  {
    heading: "menu",
    route: "/assessor",
    pages: [
      {
        heading: "Add Assessor",
        route: "/assessor/add-assessor",
        fontIcon: "fa-user-secret",
        svgIcon: "media/icons/duotune/general/gen019.svg",
      },
      // {
      //   heading: "Assessor Organization",
      //   route: "/assessor/assessor-organization",
      //   fontIcon: "fa-user-secret",
      //   svgIcon: "media/icons/duotune/general/gen019.svg",
      // },
      {
        heading: "Assessor List",
        route: "/assessor/assessor-list",
        fontIcon: "fa-user-secret",
        svgIcon: "media/icons/duotune/general/gen019.svg",
      },
      {
        heading: "Assessor Tracking",
        route: "/assessor/assessor-search",
        fontIcon: "fa-user-secret",
        svgIcon: "media/icons/duotune/general/gen019.svg",
      },
      // {
      //   heading: "Inactive Assessor List",
      //   route: "/assessor/inactive-assessor-list",
      //   fontIcon: "fa-user-secret",
      //   svgIcon: "media/icons/duotune/general/gen019.svg",
      // },
      // {
      //   sectionTitle: "Assessor Setting",
      //   fontIcon: "fa-user-secret",
      //   svgIcon: "media/icons/duotune/general/gen019.svg",
      //   sub: [
      //     {
      //       heading: "Assessor Organization",
      //       route: "/assessor/setting/assessor-organization",
      //     },
      //   ],
      // },
    ],
  },
];

export default Menu;
